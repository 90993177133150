import Rest from '@/services/Rest';

/**
 * @typedef {CountryService}
 */
export default class CountryService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/country'
}
