export default function (code,vueInstance) {
  switch (code) {
    // case 'CBE001': return "ClientCode é obrigatório." // Enviado pelo back
    // case 'CBE002': return "accountOnboardingType deve ser do tipo BANKACCOUNT OU GRAPHICACCOUNT." // Enviado pelo back
    case 'CBE003': return vueInstance.$t('error_celcoin.CBE003')
    case 'CBE004': return vueInstance.$t('error_celcoin.CBE004')
    case 'CBE005': return vueInstance.$t('error_celcoin.CBE005')
    case 'CBE006': return vueInstance.$t('error_celcoin.CBE006')
    case 'CBE007': return vueInstance.$t('error_celcoin.CBE007')
    case 'CBE008': return vueInstance.$t('error_celcoin.CBE008')
    case 'CBE009': return vueInstance.$t('error_celcoin.CBE009')
    case 'CBE010': return vueInstance.$t('error_celcoin.CBE010')
    case 'CBE011': return vueInstance.$t('error_celcoin.CBE011')
    case 'CBE012': return vueInstance.$t('error_celcoin.CBE012')
    case 'CBE013': return vueInstance.$t('error_celcoin.CBE013')
    case 'CBE014': return vueInstance.$t('error_celcoin.CBE014')
    case 'CBE015': return vueInstance.$t('error_celcoin.CBE015')
    case 'CBE016': return vueInstance.$t('error_celcoin.CBE016') // Não utilizado
    case 'CBE017': return vueInstance.$t('error_celcoin.CBE017') // Não utilizado
    case 'CBE018': return vueInstance.$t('error_celcoin.CBE018')
    case 'CBE019': return vueInstance.$t('error_celcoin.CBE019')
    case 'CBE020': return vueInstance.$t('error_celcoin.CBE020')
    case 'CBE022': return vueInstance.$t('error_celcoin.CBE022') // Back deveria estar tratando
    case 'CBE023': return vueInstance.$t('error_celcoin.CBE023') // Muito improvavel
    case 'CBE024': return vueInstance.$t('error_celcoin.CBE024') // Back deveria estar tratando
    case 'CBE025': return vueInstance.$t('error_celcoin.CBE025') // Back deveria estar tratando
    case 'CBE033': return vueInstance.$t('error_celcoin.CBE033')
    case 'CBE034': return vueInstance.$t('error_celcoin.CBE034')
    case 'CBE036': return vueInstance.$t('error_celcoin.CBE036')
    case 'CBE037': return vueInstance.$t('error_celcoin.CBE037')
    case 'CBE168': return vueInstance.$t('error_celcoin.CBE168')
    case 'CBE169': return vueInstance.$t('error_celcoin.CBE169')
    // case 'CBE305': return 'Já existe um cadastro com o mesmo clientCode enviado.' // Enviado pelo back
    default: return `${vueInstance.$t('error_celcoin.default')}${code}`
  }
}
