<template>
  <BaseModal
    name="modal-sub-users"
    size="lg"
    :title="$t('seller.settings.modal_sub_users.text_758')"
    @shown="openModal"
    @hidden="reset"
  >
    <div v-if="!edit">
      <div v-if="!invite">
        <b-row class="mb-3" data-anima="top">
          <b-col cols="12" class="flex-end">
            <BaseButton variant="info2" @click="initInvite"
              >{{ $t('seller.settings.modal_sub_users.text_759') }}</BaseButton
            >
          </b-col>
        </b-row>

        <!-- HEADER -->
        <b-row class="Table-header mb-1" data-anima="top">
          <b-col cols="1" class="d-none d-md-block"> # ID </b-col>

          <b-col cols="3" class=""> {{ $t('seller.settings.sub_users.text_2682') }} </b-col>

          <b-col cols="4" class=""> E-mail </b-col>

          <b-col cols="2" class="d-none d-md-block"> {{ $t('seller.settings.sub_users.text_2683') }} </b-col>

          <b-col cols="2" class=""> {{ $t('seller.settings.sub_users.text_2685') }} </b-col>
        </b-row>
        <!-- END HEADER -->

        <template v-if="!loading && users.length > 0" data-anima="top">
          <b-row class="Table-body" v-for="item in users" :key="item.id">
            <b-col cols="1" class="d-none d-md-block"> # {{ item.id }} </b-col>

            <b-col cols="3" class="d-none d-md-block">
              {{ item.child_user ? item.child_user.name : $t('seller.settings.sub_users.text_776') }}
            </b-col>

            <b-col cols="4" class="d-none d-md-block break">
              {{ item.child_user ? item.child_user.email : $t('seller.settings.sub_users.text_776') }}
            </b-col>

            <b-col cols="2" class="d-none d-md-block">
              {{ item.updated_at | datetime }}
            </b-col>

            <b-col cols="2" class="d-none d-md-block" v-if="item.created_at">
              <span @click="editItem(item)" class="btn p-2">
                <img
                  src="@/assets/img/icons/editar.svg"
                  class="copy-link icon-white"
                />
              </span>
              <span @click="removeSubUser(item)" class="btn p-2">
                <img
                  src="@/assets/img/icons/lixeira.svg"
                  class="copy-link icon-white"
                />
              </span>
            </b-col>
          </b-row>
        </template>

        <div
          v-if="loading"
          class="
            Table-body
            py-4
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>

        <b-row
          v-if="!loading && !users.length"
          class="Table-body justify-content-center"
        >
          <p class="nao-encontrado">{{ $t('seller.settings.modal_sub_users.text_760') }}</p>
        </b-row>
      </div>

      <div v-else>
        <b-form data-anima="top" novalidate v-if="!loading">
          <b-row class="mb-3">
            <b-col cols="6">
              <b-form-group :label="$t('seller.settings.modal_sub_users.text_2663')">
                <b-form-input
                  id="email"
                  placeholder="E-mail"
                  v-model="email"
                  :disabled="sended"
                  type="email"
                  name="email"
                  v-validate="'required|email'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('email')">
                  {{ $t('seller.settings.modal_sub_users.text_2664') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3" v-if="sended">
            <b-col cols="6">
              <b-form-group :label="$t('seller.settings.modal_sub_users.text_2665')">
                <b-form-input
                  :placeholder="$t('seller.settings.modal_sub_users.text_2674')"
                  v-model="code"
                  type="text"
                  name="code"
                  v-validate="'required'"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('code')">
                  {{ $t('seller.settings.modal_sub_users.text_2666') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>

    <div v-else>
      <h5 class="mb-5">{{ $t('seller.settings.modal_sub_users.text_2667') }}</h5>
      <div class="flex-edit mb-5">
        <img src="@/assets/img/icons/affiliate.svg" />
        <div>
          {{ editUser.child_user.name }} 
          -
          {{ editUser.child_user.email }}
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('seller.settings.modal_sub_users.text_2668')" label-for="date-in">
            <multiselect
              v-model="new_group"
              :options="group_list"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :placeholder="$t('seller.settings.modal_sub_users.text_2668')"
              label="text"
              track-by="id"
              selectLabel=""
              deselectLabel=""
              selectedLabel="✔️"
              class="multi-select-input"
              :class="{'heaven': isHeaven}"
            >
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        v-if="!invite"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.settings.modal_sub_users.text_2669') }}
      </BaseButton>
      <BaseButton
        v-if="invite"
        variant="link-info"
        class="mr-4"
        @click="(invite = false), fetchSubUsers()"
      >
        {{ $t('seller.settings.modal_sub_users.text_2670') }}
      </BaseButton>
      <BaseButton
        v-if="invite && sended === false"
        variant="info2"
        :disabled="loading"
        @click="onSubmit"
      >
        {{ $t('seller.settings.modal_sub_users.text_2671') }}
      </BaseButton>
      <BaseButton
        v-if="sended === true && invite"
        variant="info2"
        :disabled="loading"
        @click="onSubmit2"
      >
        {{ $t('seller.settings.modal_sub_users.text_2672') }}
      </BaseButton>
      <BaseButton
        v-if="edit"
        variant="info2"
        :disabled="loading"
        @click="onSubmit3"
      >
        {{ $t('seller.settings.modal_sub_users.text_2673') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import SubUserService from "@/services/resources/SubUsersService";
const serviceSubUser = SubUserService.build();
import SubUsersGroupService from "@/services/resources/SubUsersGroupService";
const serviceSubGroup = SubUsersGroupService.build();

import Vue from "vue";
import Paginate from "@/components/shared/Paginate";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Paginate,
    Multiselect
  },
  props: ["group_id"],
  data() {
    return {
      loading: true,
      users: [],
      invite: false,
      email: "",
      sended: false,
      code: "",
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      edit: false,
      editUser: null,
      new_group: null,
      group_list: [],
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    editItem(item) {
      if(item.child_user === null){
        this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_761'), {
          title: this.$t('seller.settings.modal_sub_users.text_2675'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      this.fetchGroups();
      this.edit = true;
      this.editUser = item;
    },
    fetchGroups() {
      this.group_list = [
        { value: null, text: this.$t('seller.settings.modal_sub_users.text_762'), $isDisabled: true },
      ];

      serviceSubGroup
        .read("")
        .then((response) => {
          response.forEach((element, index) => {
            this.group_list.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    removeSubUser(item) {
      if(item.child_user === null){
        this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_763'), {
          title: this.$t('seller.settings.modal_sub_users.text_2675'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      Vue.swal({
        title: this.$t('seller.settings.modal_sub_users.text_1676'),
        text: `${this.$t('seller.settings.modal_sub_users.text_764')} ${item.child_user.name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('seller.settings.modal_sub_users.text_2678'),
        cancelButtonText: this.$t('seller.settings.modal_sub_users.text_2677'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          let data = {
            id: item.id,
          };
          serviceSubUser
            .destroy(data)
            .then((response) => {
              this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_765'), {
                title: this.$t('seller.settings.modal_sub_users.text_2675'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.fetchSubUsers();
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    initInvite() {
      this.invite = true;
      this.email = "";
      this.sended = false;
      this.code = "";
    },
    reset() {
      this.invite = false;
      this.email = "";
      this.sended = false;
      this.code = "";
      this.users = [];
      this.edit = false;
      this.editUser = null;
      this.new_group = null;
      this.group_list = [];


      this.$emit("close");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchSubUsers();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchSubUsers();
    },
    fetchSubUsers() {
      this.loading = true;
      serviceSubGroup
        .read(this.group_id)
        .then((response) => {
          if(response.sub_user){
            this.users = response.sub_user;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            id: "/invite/send-parent-code",
            email: this.email,
          };

          serviceSubUser
            .createId(data)
            .then(() => {
              this.sended = true;
              this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_766'), {
                title: this.$t('seller.settings.modal_sub_users.text_2679'),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    onSubmit2() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            id: "/invite/confirm-parent-code",
            code: this.code,
          };

          serviceSubUser
            .createId(data)
            .then(() => {
              this.sended = true;
              this.sendInvite();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    onSubmit3(){
      let data = {
        id: this.editUser.id,
        sub_user_role_id: this.new_group.value,
      }
      serviceSubUser
      .update(data)
      .then(() => {
        this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_767'), {
          title: this.$t('seller.settings.modal_sub_users.text_2679'),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.edit = false;
        this.editUser = null;
        this.new_group = null;
        this.fetchSubUsers();
      })
      .catch((err) => {
        console.log(err);
      });
    },
    sendInvite() {
      let data = {
        id: "/invite/set-group",
        parent_code: this.code,
        sub_user_role_id: this.group_id.toString(),
        child_email: this.email,
      };

      serviceSubUser
        .createId(data)
        .then(() => {
          this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_768'), {
            title: this.$t('seller.settings.modal_sub_users.text_2679'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.invite = false;
          this.loading = false;
          this.edit = false;
          this.editUser = null;
          this.fetchSubUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resolveResponse() {
      this.$bvToast.toast(this.$t('seller.settings.modal_sub_users.text_769'), {
        title: this.$t('seller.settings.modal_sub_users.text_2675'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.$emit("close");
      this.reset();
      this.$root.$emit("bv::hide::modal", "modal-permission-group", "#btnShow");
    },
    openModal() {
      this.fetchSubUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
}

h5 {
  font-weight: 600;
  font-size: 14px;
  color: #2133d2;
}

.flex-edit{
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  align-items: center;
  div{
    font-weight: 600;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

p {
  font-size: 12px;
  font-weight: 300;
}
.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;
  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.break{
  word-break: break-all;
}

.icon-white {
  width: 14px;
  filter: invert(50%);
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}
</style>
