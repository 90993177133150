import Rest from '@/services/Rest';

/**
 * @typedef {WebhookTokenService}
 */
export default class WebhookTokenService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/user-has-webhook'
}