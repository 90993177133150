<template>
  <div>
    <div class="address-item" v-if="!loading">
      <div class="infos">
        <div class="icon">
          <img src="@/assets/img/icons/endereco.svg" />
        </div>
        <span>
          {{ mountAddress(endereco) }}
          <img src="@/assets/img/icons/mini-check.svg" v-if="principal" />
        </span>
      </div>
      <div class="actions" v-if="actions">
        <b-dropdown
          size="sm"
          right
          dropup
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <img src="@/assets/img/icons/more2.svg" class="btn-more" />
          </template>
          <b-dropdown-item class="drop-item" @click="mainAddress">
            {{ $t('seller.address.item.text_104') }}
          </b-dropdown-item>
          <b-dropdown-item class="drop-item" @click="removeAddress">
            {{ $t('seller.address.item.text_105') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="address-item" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import AddressService from "@/services/resources/AddressService";
const serviceAddress = AddressService.build();

export default {
  name: "AddressItem",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    endereco: {
      type: Object,
      default: () => {},
    },
    principal: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeAddress() {
      this.loading = true;
      serviceAddress
        .destroy({ id: this.endereco.id })
        .then(() => {
          this.$emit("update");
          this.$bvToast.toast(this.$t('seller.address.item.text_106'), {
            title: this.$t('seller.address.item.text_107'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mainAddress() {
      this.loading = true;
      let data = Object.assign(this.endereco, {});
      data.main = true;
      serviceAddress
        .update(data)
        .then(() => {
          this.$emit("update", data);
          this.$bvToast.toast(this.$t('seller.address.item.text_108'), {
            title: this.$t('seller.address.item.text_107'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mountAddress(endereco) {
      return `${endereco.street}, ${endereco.number}, ${endereco.neighborhood}, ${endereco.city}/${endereco.state} - CEP ${endereco.zip_code}`;
    },
  },
};
</script>

<style scoped>
.address-item {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.address-item span {
  font-size: 13px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 7px;
}
.address-item .infos {
  display: flex;
  align-items: center;
  gap: 15px;
}
.infos .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(33, 51, 210, 0.1);
  border-radius: 5px;
}
.btn-more {
  background: rgba(33, 51, 210, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  border-radius: 5px;
}
</style>