import Rest from '@/services/Rest';

/**
 * @typedef {SubUsersGroupService}
 */
export default class SubUsersGroupService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/sub-user-group'
}