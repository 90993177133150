<template>
  <b-modal
    :id="id ? id : 'modal-pf-account'"
    name="modal-pf-account"
    size="md"
    :hide-footer="step === 1 || step === 0"
    @shown="openModal"
    @hidden="closeModal"
  >
    <template v-slot:modal-title>
      <h5 class="modal-pf-account-title">
        {{
           step === 0
           ? $t('views.seller.settings.documents') :
           step === 1
           ? $t('views.seller.settings.text_1417') :
           step === 2
           ? $t('views.seller.settings.text_1418') :
           step === 3
           ? $t('views.seller.settings.text_1419') :
           step === 4
           ? $t('views.seller.settings.text_1420') : ''
        }}
      </h5>
      <div class="custom-error p-3 mb-3" v-if="errorCreateCelcoin.length">
        <div class="d-flex align-items-end mb-3">
          <img class="pr-2" src="@/assets/img/icons/warning-error.svg"/>
          <span class="custom-error-subtitle">{{ $t('client.pending') }}</span><br/>
        </div>
        <ul class="custom-ul">
          <li class="custom-li mt-2" v-for="(erro_code, i) in errorCreateCelcoin" :key="'err_create_'+i">
            <span class="custom-error-text">
              {{ getErrorMsg(erro_code) }}
            </span>
          </li>
        </ul>
      </div>
      <!-- <h6 class="information mt-3" v-if="step > 1">
        {{ type === 'PF' ? $t('views.seller.settings.text_1435') : $t('views.seller.settings.text_1436') }}
      </h6> -->
    </template>

    <!-- Advise -->
    <template v-if="step === 0">
      <!-- <h5 class="modal-pf-account-subtitle mb-4">{{ $t('views.seller.settings.documents') }}</h5> -->
      <div class="identity-verification-container">
        <div class="icon-verification">
          <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 9.30132V14.0869M14.5 18.8725H14.512M12.4542 3.15183L2.32066 20.0689C2.11173 20.4307 2.00118 20.8409 2.00001 21.2588C1.99884 21.6766 2.10709 22.0874 2.31399 22.4504C2.52089 22.8134 2.81923 23.1158 3.17932 23.3277C3.53942 23.5396 3.94872 23.6535 4.3665 23.6581H24.6335C25.0513 23.6535 25.4606 23.5396 25.8207 23.3277C26.1808 23.1158 26.4791 22.8134 26.686 22.4504C26.8929 22.0874 27.0012 21.6766 27 21.2588C26.9988 20.8409 26.8883 20.4307 26.6793 20.0689L16.5458 3.15183C16.3326 2.80021 16.0323 2.5095 15.6739 2.30775C15.3155 2.10599 14.9112 2 14.5 2C14.0888 2 13.6845 2.10599 13.3261 2.30775C12.9677 2.5095 12.6674 2.80021 12.4542 3.15183Z" stroke="#FF9E44" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <span class="title-verification">
          {{ $t('views.seller.settings.identity_validation_pending') }}
        </span>
        <span class="subtitle-verification">
          {{ $t('views.seller.settings.make_identity_verification') }}
        </span>
        <div @click="startIdentityVerification" class="btn-start-verification">
          {{ $t('views.seller.settings.start_identity_verification') }}
        </div>
      </div>
    </template>

    <!-- Account type -->
    <template v-if="step === 1">
      <h5 class="modal-pf-account-subtitle mb-4">{{ $t('views.seller.settings.text_1421') }}</h5>
      <div class="modal-pf-account-border modal-pf-account-text cursor-pointer p-4 mb-4" :class="{'active': type === 'PF'}" @click="selectType('PF')">
        <div class="d-flex justify-content-between mb-2">
          <div><b>{{ $t('views.seller.settings.text_1422') }}</b></div>
        </div>
        <div class="modal-pf-account-description" :class="{'active': type === 'PF'}">
          {{ $t('views.seller.settings.text_1423') }} <strong>{{ $t('views.seller.settings.text_1424') }}</strong><br>
          {{ $t('views.seller.settings.text_1425') }}
        </div>
      </div>
      <div class="modal-pf-account-border modal-pf-account-text cursor-pointer p-4" :class="{'active': type === 'PJ' || type === 'PENDING_PJ'}" @click="selectType('PJ')">
        <div class="d-flex justify-content-between mb-2">
          <div><b>{{ $t('views.seller.settings.text_1426') }}</b></div>
        </div>
        <div class="modal-pf-account-description" :class="{'active': type === 'PJ' || type === 'PENDING_PJ'}">
          {{ $t('views.seller.settings.text_1427') }} <strong>{{ $t('views.seller.settings.text_1428') }}</strong><br>
          {{ $t('views.seller.settings.text_1429') }}
        </div>
      </div>
    </template>

    <!-- Personal Data -->
    <template v-if="step === 2">
      <b-row v-if="!loadingGetValidations">
        <b-col cols="12">
          <h5 class="modal-pf-account-subtitle mb-4">{{ $t('views.seller.settings.insert_your_personal_data') }}</h5>
          <div class="advise-container">
            <div class="advise-icon">
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.11111 8V4.88889C4.11111 3.85749 4.52083 2.86834 5.25014 2.13903C5.97945 1.40972 6.9686 1 8 1C9.0314 1 10.0206 1.40972 10.7499 2.13903C11.4792 2.86834 11.8889 3.85749 11.8889 4.88889V8M2.55556 8H13.4444C14.3036 8 15 8.69645 15 9.55556V15C15 15.8591 14.3036 16.5556 13.4444 16.5556H2.55556C1.69645 16.5556 1 15.8591 1 15V9.55556C1 8.69645 1.69645 8 2.55556 8Z" stroke="#002092" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <span class="advise-text">
              {{ $t('views.seller.settings.advise') }}
            </span>
          </div>
          <!-- Name -->
          <b-form-group :label="$t('views.seller.my_account.text_1569')" label-for="name">
            <b-form-input
              id="name"
              name="name"
              v-model="user.name"
              type="text"
              :disabled="fieldsDisabled.name"
              :placeholder="$t('views.seller.my_account.text_1160')"
              v-validate="{ required: !loading, alpha_spaces: true }"
              :class="{'heaven': isHeaven}"
              @blur="formatName(user.name)"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('name')">
              {{ $t('views.seller.my_account.text_1161') }}
              {{ messageNameStepNumber }}
            </b-form-invalid-feedback>
            <div v-if="showNameValid && !errors.has('name')">
              <small 
                v-for="(validation,index) in nameValidationSteps(user.name)" 
                :key="index" 
                :class="{name_valid:validation.validated , name_invalid:!validation.validated}">
                {{ validation.step }}
              </small>
            </div>
          </b-form-group>
          <!-- Social Name -->
          <b-form-group :label="$t('views.seller.my_account.social_name')" label-for="social_name">
            <b-form-input
              id="social_name"
              name="social_name"
              v-model="user.social_name"
              type="text"
              :placeholder="$t('views.seller.my_account.insert_your_social_name')"
              v-validate="{ required: !loading, alpha_spaces: true }"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('social_name')">
              {{ $t('views.seller.my_account.text_1161') }}
              {{ messageNameStepNumber }}
            </b-form-invalid-feedback>
            <div v-if="showNameValid && !errors.has('social_name')">
              <small 
                v-for="(validation,index) in nameValidationSteps(user.social_name)" 
                :key="index" 
                :class="{name_valid:validation.validated , name_invalid:!validation.validated}">
                {{ validation.step }}
              </small>
            </div>
          </b-form-group>
          <!-- CPF -->
          <b-form-group :label="$t('views.seller.my_account.text_1575')" label-for="cpf" id="campo-cpf">
            <b-form-input
              id="cpf"
              name="cpf"
              v-model="user.cpf_cnpj"
              type="text"
              placeholder="###.###.###-##"
              v-validate="{required: !loading, cpf_cnpj: true}"
              v-mask="['###.###.###-##']"
              :disabled="userLogged.cpf_cnpj ? true : false"
              :readonly="status_validation.status == 'APPROVED' && userLogged.cpf_cnpj ? true : false"
              :class="{'heaven': isHeaven}"
              @input="updateCpfIsValid"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('cpf')">
              {{ $t('views.seller.my_account.text_1164') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-tooltip
            v-if="status_validation.status == 'APPROVED' && userLogged.cpf_cnpj ? true : false"
            target="campo-cpf"
            :title="$t('views.seller.my_account.text_1165')"
          />
          <!-- Birth Date -->
          <b-form-group :label="$t('seller.address.listagem_enderecos.birth_date')" label-for="birth_date">
            <b-form-input
              id="birth_date"
              name="birth_date"
              v-model="user.birth_date"
              :disabled="fieldsDisabled.birth_date"
              type="date"
              v-validate="{
                required: true,
                date_format: 'yyyy-MM-dd',
                date_between: ['1900-01-01',moment().format('YYYY-MM-DD')]
              }"
              :class="{ heaven: isHeaven }"
              placeholder="##/##/####"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('birth_date')">
              {{ $t('seller.address.listagem_enderecos.fill_birth_date') }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Mother Name -->
          <b-form-group :label="$t('views.seller.my_account.text_1569_1')" label-for="mother_name">
            <b-form-input
              id="mother_name"
              name="mother_name"
              v-model="user.mother_name"
              :disabled="fieldsDisabled.mother_name"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1160')"
              v-validate="{ required: !loading, alpha_spaces: true }"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('mother_name')">
              {{ $t('views.seller.my_account.text_1161_1') }}
            </b-form-invalid-feedback>
            <div v-if="showMotherNameValid && !errors.has('mother_name')">
              <small 
                v-for="(validation,index) in nameMotherValidationSteps(user.mother_name)" 
                :key="index" 
                :class="{name_valid:validation.validated , name_invalid:!validation.validated}">
                {{ validation.step }}
              </small>
            </div>
          </b-form-group>
          <!-- CellPhone -->
          <b-form-group :label="$t('views.seller.my_account.text_1570')" label-for="phone">
            <VuePhoneNumberInput
              v-model="whatsapp"
              :translations="$i18n.locale === 'pt' ? translations_pt : (
                $i18n.locale === 'es' ? translations_es : translations_en
              )"
              error-color="#ff0c37"
              size="lg"
              :default-country-code="user.country_phone ? user.country_phone : isHeaven ? 'US' : 'BR'"
              :preferred-countries="isHeaven ? ['US', 'BR', 'PT', 'FR', 'ES'] : ['BR', 'PT', 'US', 'FR', 'ES']"
              ref="telinput"
              :required="true"
              :countries-height="50"
              :border-radius="5"
              :class="{'heaven': isHeaven}"
              @update="onUpdatePessoal"
            />
          </b-form-group>
          <!-- Is Politically Exposed Person -->
          <b-row>
            <b-col class="warning p-3 mt-2 mb-0" sm="7" md="4">
              <img v-show="user.is_politically_exposed_person" id="warning" src="@/assets/img/icons/warning.svg" />
              <b-tooltip target="warning">
                <template>
                  {{ $t('views.seller.my_account.text_1257') }}: 
                  <a target="_blank" href="https://www.gov.br/coaf/pt-br/assuntos/informacoes-as-pessoas-obrigadas/o-que-sao-pessoas-expostas-politicamente-peps">{{ $t('views.seller.settings.text_1438') }}</a>
                </template>
              </b-tooltip>
              <span class="have-account">
                {{ $t('views.seller.my_account.text_1256') }} 
              </span>
            </b-col>
            <b-col class="p-2 mt-3 mb-0" cols="3" md="3">
              <switch-button :isChecked="isChecked" :newValueReturn="newValueReturnSwitch"/>
            </b-col>
          </b-row>
          <!-- Use Terms -->
          <b-form-group
            label=""
            label-for="accepted_terms"
            class="mt-3"
          >
            <b-form-checkbox
              id="accepted_terms"
              v-model="user.accepted_terms"
              name="accepted_terms"
              v-validate="'required'"
            >
              <span class="have-account">
                {{ $t("i_agree_the") }}
                <a
                  :href="isHeaven
                    ? 'https://heaven.me/heaven-termos-de-uso/'
                    : 'https://greenn.com.br/termos-de-uso-greenn/'
                    "
                  target="_blank"
                  class="signin"
                  >
                  {{ $t("terms_of_use_and_policies") }},
                </a>
                {{ $t("i_have_science_of") }}
                <a
                  :href="isHeaven
                    ? 'https://heaven.me/heaven-termos-de-uso/'
                    : 'https://greenn.com.br/politica-de-privacidade-greenn/'
                    "
                  target="_blank"
                  class="signin"
                  >
                  {{ $t("privacy_police") }}
                </a>
                {{
                  isHeaven
                    ? $t("views.seller.register.text_1439_1")
                    : $t("views.seller.register.text_1439")
                }}
                {{ $t("agree_text_1") }}
              </span>
            </b-form-checkbox>
            <b-form-invalid-feedback :state="!errors.has('accepted_terms')">
              {{ $t("views.seller.register.text_1439_2") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Loading -->
      <b-row v-else class="d-flex justify-content-center">
        <div>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-row>
    </template>

    <!-- Address -->
    <template v-if="step === 3">
      <!-- Zipcode -->
      <b-row class="px-2">
        <b-col class="px-2 pt-2" cols="12" md="6">
          <b-form-group :label="$t('views.seller.my_account.text_1578')" label-for="zip_code">
            <b-form-input
              id="zip_code"
              name="zip_code"
              v-model="address.zip_code"
              :disabled="hasAddress"
              :placeholder="$t('views.seller.my_account.text_1169')"
              v-mask="['#####-###']"
              type="text"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
              @keyup="preencherCep('usuario')"
              max="8"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('zip_code')">
              {{ $t('seller.address.listagem_enderecos.fill_postal_code') }}
            </b-form-invalid-feedback>
            <div v-if="showZipCodeValid && !errors.has('zip_code')">
              <small 
                class="name_invalid">
                {{ $t('seller.address.listagem_enderecos.fill_postal_code_1') }}
              </small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-2">
        <!-- Street -->
        <b-col class="px-2" cols="12" md="8">
          <b-form-group :label="$t('views.seller.my_account.text_1170')" label-for="street">
            <b-form-input
              id="street"
              name="street"
              v-model="address.street"
              :disabled="hasAddress"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1170')"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('street')">
              {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Number -->
        <b-col class="px-2" cols="12" md="4">
          <b-form-group :label="$t('views.seller.my_account.text_1579')" label-for="number">
            <b-form-input
              id="number"
              name="number"
              v-model="address.number"
              :disabled="hasAddress"
              type="text"
              v-mask="['########']"
              :placeholder="'123'"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('number')">
              {{ $t('seller.address.listagem_enderecos.fill_number') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Complement -->
      <!-- <b-row class="px-2">
        <b-col class="px-2" cols="12" md="12">
          <b-form-group :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
            <b-form-input
              id="complement"
              v-model="address.complement"
              :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
              type="text"
              name="complement"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row> -->
      <!-- Neighborhood -->
      <b-row class="px-2">
        <b-col class="px-2" cols="12" md="12">
          <b-form-group :label="$t('views.seller.my_account.text_1580')" label-for="neighborhood">
            <b-form-input
              id="neighborhood"
              name="neighborhood"
              v-model="address.neighborhood"
              :disabled="hasAddress"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1172')"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('neighborhood')">
              {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col class="px-2 d-none" cols="12" md="12" >
          <b-form-group :label="$t('views.seller.my_account.text_1580')" label-for="city">
            <b-form-input
              id="city"
              name="city"
              v-model="address.city"
              :disabled="hasAddress"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1172')"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('city')">
              {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="px-2">
        <!-- City -->
        <b-col class="px-2" cols="12" md="6">
          <b-form-group :label="$t('views.seller.my_account.text_1581')" label-for="city">
            <b-form-input
              id="city"
              name="city"
              v-model="address.city"
              :disabled="hasAddress"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1582')"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('city')">
              {{ $t('seller.address.listagem_enderecos.fill_city') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- State -->
        <b-col class="px-2" cols="12" md="6">
          <b-form-group :label="$t('views.seller.my_account.text_1583')" label-for="state">
            <b-form-input
              id="state"
              name="state"
              v-model="address.state"
              :disabled="hasAddress"
              type="text"
              :placeholder="$t('views.seller.my_account.text_1584')"
              v-validate="{required: !loading}"
              :class="{'heaven': isHeaven}"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('state')">
              {{ $t('seller.address.listagem_enderecos.fill_state') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <!-- Company -->
    <template v-if="step === 4">
      <h5 class="modal-pf-account-subtitle mb-4">{{ $t('views.seller.settings.insert_your_personal_data') }}</h5>
      <div class="advise-container">
        <div class="advise-icon">
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.11111 8V4.88889C4.11111 3.85749 4.52083 2.86834 5.25014 2.13903C5.97945 1.40972 6.9686 1 8 1C9.0314 1 10.0206 1.40972 10.7499 2.13903C11.4792 2.86834 11.8889 3.85749 11.8889 4.88889V8M2.55556 8H13.4444C14.3036 8 15 8.69645 15 9.55556V15C15 15.8591 14.3036 16.5556 13.4444 16.5556H2.55556C1.69645 16.5556 1 15.8591 1 15V9.55556C1 8.69645 1.69645 8 2.55556 8Z" stroke="#002092" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <span class="advise-text">
          {{ $t('views.seller.settings.advise') }}
        </span>
      </div>
      <!-- Social Reazon -->
      <b-form-group :label="$t('views.seller.my_account.text_1586')+' *'" label-for="name_company">
        <b-form-input
          id="name_company"
          name="name_company"
          v-model="company.name"          
          type="text"
          :placeholder="$t('views.seller.my_account.text_1177')"
          v-validate="'required'"
          :class="{'heaven': isHeaven}"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('name_company')">
          {{ $t('views.seller.my_account.text_1178') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- Fantasy Name -->
      <b-form-group :label="$t('views.seller.my_account.text_1179')" label-for="fantasy_name">
        <b-form-input
          id="fantasy_name"
          name="fantasy_name"
          v-model="company.fantasy_name"
          :disabled="blocked_company_fields.fantasy_name"
          type="text"
          :placeholder="$t('views.seller.my_account.text_1180')"
          :class="{'heaven': isHeaven}"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('fantasy_name')">
          {{ $t('views.seller.my_account.text_1178') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- CNPJ -->
      <b-form-group :label="$t('views.seller.my_account.text_1181')+' *'" label-for="cnpj" id="campo-cnpj">
        <b-form-input
          id="cnpj"
          name="cnpj"
          v-model="company.cnpj"
          :placeholder="$t('views.seller.my_account.text_1182')"
          v-mask="['##.###.###/####-##']"
          v-validate="{required: !loading}"
          :disabled="blocked_company_fields.cnpj"
          :readonly="checkStatus(company.documents, 'APPROVED').length ? true : false"
          :class="{'heaven': isHeaven}"
          @input="updateCnpjIsValid"
        ></b-form-input>
        <b-form-invalid-feedback :state="(errors.items.length && errors.items.some(x => x.field === 'cnpj')) || !cpnjIsValid ? false : true">
          {{
            !errors.items.some(x => x.field === 'cnpj') && !cpnjIsValid
              ? $t('views.seller.my_account.text_1587_1')
              : $t('views.seller.my_account.text_1587')
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-tooltip
        v-if="checkStatus(company.documents, 'APPROVED').length ? true : false"
        target="campo-cnpj"
        :title="$t('views.seller.my_account.text_1183')"
      />
      <!-- Use Same PF Address -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div class="modal-pf-account-info-checkbox">
            {{ $t('views.seller.my_account.text_1233') }}
          </div>
          <span class="modal-pf-account-descripcao-checkbox">
            {{ $t('views.seller.my_account.text_1234') }}
          </span>
        </div>
        <b-form-group class="mt-4" label-for="use_same_pf_address ">
          <b-form-checkbox
            v-model="use_same_pf_address"
            name="use_same_pf_address  "
            size="lg"
            switch
            button-variant="danger"
          >
  
          </b-form-checkbox>
        </b-form-group>
      </div>

      <!-- Company Address -->
      <template v-if="!use_same_pf_address">
        <!-- Zipcode -->
        <b-row class="px-2">
          <b-col class="px-2 pt-2" cols="12" md="6">
            <b-form-group :label="$t('views.seller.my_account.text_1578')" label-for="zip_code">
              <b-form-input
                id="zip_code"
                name="zip_code"
                v-model="address_company.zip_code"
                :disabled="blocked_company_fields.zip_code"
                :placeholder="$t('views.seller.my_account.text_1169')"
                v-mask="['#####-###']"
                type="text"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
                @keyup="preencherCep('empresa')"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('zip_code')">
                {{ $t('seller.address.listagem_enderecos.fill_postal_code') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="px-2">
          <!-- Street -->
          <b-col class="px-2" cols="12" md="8">
            <b-form-group :label="$t('views.seller.my_account.text_1170')" label-for="street">
              <b-form-input
                id="street"
                name="street"
                v-model="address_company.street"
                :disabled="blocked_company_fields.street"
                type="text"
                :placeholder="$t('views.seller.my_account.text_1170')"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('street')">
                {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- Number -->
          <b-col class="px-2" cols="12" md="4">
            <b-form-group :label="$t('views.seller.my_account.text_1579')" label-for="number">
              <b-form-input
                id="num_company"
                name="number"
                v-model="address_company.number"
                :disabled="blocked_company_fields.number"
                type="text"
                v-mask="['########']"
                :placeholder="'123'"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('number')">
                {{ $t('seller.address.listagem_enderecos.fill_number') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Complement -->
        <!-- <b-row class="px-2">
          <b-col class="px-2" cols="12" md="12">
            <b-form-group :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
              <b-form-input
                id="complement"
                v-model="address_company.complement"
                :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
                type="text"
                name="complement"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row> -->
        <!-- Neighborhood -->
        <b-row class="px-2">
          <b-col class="px-2" cols="12" md="12">
            <b-form-group :label="$t('views.seller.my_account.text_1580')" label-for="neighborhood">
              <b-form-input
                id="neighborhood"
                name="neighborhood"
                v-model="address_company.neighborhood"
                :disabled="blocked_company_fields.neighborhood"
                type="text"
                :placeholder="$t('views.seller.my_account.text_1172')"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('neighborhood')">
                {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="px-2">
          <!-- Cidade -->
          <b-col class="px-2" cols="12" md="6">
            <b-form-group :label="$t('views.seller.my_account.text_1581')" label-for="city">
              <b-form-input
                id="city"
                name="city"
                v-model="address_company.city"
                :disabled="blocked_company_fields.city"
                type="text"
                :placeholder="$t('views.seller.my_account.text_1582')"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('city')">
                {{ $t('seller.address.listagem_enderecos.fill_city') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <!-- State -->
          <b-col class="px-2" cols="12" md="6">
            <b-form-group :label="$t('views.seller.my_account.text_1583')" label-for="state">
              <b-form-input
                id="state"
                name="state"
                v-model="address_company.state"
                :disabled="blocked_company_fields.state"
                type="text"
                :placeholder="$t('views.seller.my_account.text_1584')"
                v-validate="{required: !loading}"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('state')">
                {{ $t('seller.address.listagem_enderecos.fill_state') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </template>

    <template v-if="step !== 1 && step !== 0" v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end mb-4 w-100">
        <div v-if="isRecebedor && step === 2" class="d-md-flex justify-content-end w-100">
          <BaseButton variant="black" :loading="loading" :disabled="!user.accepted_terms" @click="nextStep">
            {{ isLastStep ? $t('views.seller.settings.text_1430') : $t('views.seller.settings.text_1431') }}
          </BaseButton>
        </div>
        <div v-else class="d-md-flex justify-content-between w-100">
          <BaseButton variant="gray" @click="step--">
            {{ $t('views.seller.settings.text_1432') }}
          </BaseButton>
          <BaseButton variant="black" :loading="loading" :disabled="!user.accepted_terms" @click="nextStep">
            {{ isLastStep ? $t('views.seller.settings.text_1430') : $t('views.seller.settings.text_1431') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import validateCNPJ from '@/utils/validateCNPJ.js'
import validateCPF from '@/utils/validateCPF.js'
import { mapActions, mapGetters } from "vuex"
import moment from '@/plugins/moment'
import errorCreateCelcoin from "@/utils/errorCreateCelcoin.js"
import SwitchButton from '@/components/SwitchButton.vue'

import UserService from '@/services/resources/UserService'
import ValidationService from '@/services/resources/ValidationService'
import UserSettingsService from "@/services/resources/UserSettingsService"

const serviceUser = UserService.build()
const serviceValidation = ValidationService.build()
const serviceSettings = UserSettingsService.build();

export default {
  name: 'ModalAccountType',
  components: { VuePhoneNumberInput, SwitchButton },
  props: ['id', 'isRecebedor', 'recebedorType'],
  data() {
    return {
      moment: moment,
      step: 0,
      type: 'PF',
      user: {
        name: null,
        cpf: null,
        birth_date: null,
        mother_name: null,
        cellphone: '',
        country_phone: '',
        is_politically_exposed_person: false,
        accepted_terms: false,
      },
      address: {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null
      },
      blocked_company_fields: {
        name: false,
        fantasy_name: false,
        cnpj: false,
        documents: false,
        zip_code: false,
        number: false,
        street: false,
        neighborhood: false,
        city: false,
        state: false,
        complement: false
      },
      company: {
        name: null,
        fantasy_name: null,
        cnpj: null,
        documents: []
      },
      address_company: {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null,
      },
      whatsapp: '',
      loading: false,
      loadingGetValidations: false,
      status_validation: {
        status: '',
        observation: '',
      },
      translations_pt: {
        countrySelectorLabel: 'Código do país',
        countrySelectorError: 'Escolha um país',
        phoneNumberLabel: 'Número de Telefone',
        example: 'Exemplo: ',
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      cpnjIsValid: true,
      cpfIsValid: true,
      use_same_pf_address: true,
      showNameValid: false,
      showMotherNameValid: false,
      showZipCodeValid: false,
      isChecked: false,
    }
  },
  watch:{
    'address.zip_code'(){
      if(this.address.zip_code?.length === 9){
        this.showZipCodeValid = false;
        return
      }
      this.showZipCodeValid = true;
    },
    'user.name'(){
      const allValidatedName = this.nameValidationSteps(this.user.name);
      const isAllValidatedName = allValidatedName.every((step) => step.validated === true);
      if (!isAllValidatedName) {
        this.showNameValid = true;
        return;
      }
      this.showNameValid = false;
    },
    'user.mother_name'(){
      const allValidatedMotherName = this.nameMotherValidationSteps(this.user.mother_name);
      const isAllValidatedMotherName = allValidatedMotherName.every((step) => step.validated === true);
      if (!isAllValidatedMotherName) {
        this.showMotherNameValid = true;
        return;
      }
      this.showMotherNameValid = false;
    },
  },
  computed: {
    fieldsDisabled() {
      const enriched_data = this.user.enriched_data == '1' ? true : false;
      let birth_date = enriched_data
      let name = enriched_data
      let mother_name = enriched_data
      let address = enriched_data

      return {
        birth_date,
        name,
        mother_name,
        address
      }
    },
    messageNameStepNumber(){
      const message = this.$t('views.seller.validation_account_type.text_4')
      const messageLower = message.charAt(0).toLowerCase() + message.slice(1);
      const validationStep = this.nameValidationSteps(this.user.name).find((steps) => steps.step === message).validated;
      return !validationStep ? messageLower : '';
    },
    ...mapGetters({
      kyc_personal_checklist: 'getKycPersonalChecklist',
      errorCreateCelcoin: 'getErrorCreateCelcoin'
    }),
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
    isLastStep() {
      if((this.type === 'PJ' || this.type === 'PENDING_PJ') && this.step === 4 || this.type === 'PF' && this.step === 3) {
        return true
      } else {
        return false
      }
    },
    hasAddress() {
      return !!this.userLogged.address?.zip_code;
    }
  },
  methods: {
    ...mapActions([
      'fetchUserSettings'
    ]),
    formatName() {
      let user = this.user
      let text = user['name']
      if (!text) {
        return;
      }
      text = text.replace(/\d/g, ''); // Apenas letras
      text = text.replace(/\s+/g, ' ').trim(); // Sem espaços duplos
      text = text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()); // Iniciais em maiúsculo

      user['name'] = text
      this.user = {...user}

      if (text.split(' ').length <= 1) {
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
          title: this.$t('views.seller.my_account.text_1198'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        })
      }
    },
    getErrorMsg(code) {
      return errorCreateCelcoin(code,this)
    },
    newValueReturnSwitch(value) {
      this.user.is_politically_exposed_person = value
    },
    closeModal(){
      this.$emit("close",this.type);
    },
    updateBankAccountType() {
      const type = this.type
      if(type === 'PF') {
        serviceSettings.create({type: 'PF'}, '/account-type')
          .then(() => {
            this.$bvToast.toast(this.$t('views.seller.settings.text_1390'), {
              title: this.$t('views.seller.settings.text_1391'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            })
          })
          .catch(err => console.error(err))
      } else {
        serviceSettings.create({type: 'PJ'}, '/account-type')
          .then(() => {
            this.$bvToast.toast(this.$t('views.seller.settings.text_1390'), {
              title: this.$t('views.seller.settings.text_1391'),
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: true
            });
          })
          .catch(err => console.error(err))
      }
    },
    updateUser() {
      if(this.loading) return
      this.loading = true
      let data = {}
      if(this.type === 'PF') data = Object.assign({}, {...this.user, address: this.address, is_kyc: true})
      if(this.type === 'PJ' || this.type === 'PENDING_PJ') {
        data = Object.assign({}, {...this.user, address: this.address, company: this.company, is_kyc: true})
        if(!this.use_same_pf_address) data.company = {...this.address_company, ...data.company}
        else data.company = {...this.address, ...data.company}

        delete data.company.documents
      }
      data._method = 'PUT'
      data = this.jsonToFormData(data)

      serviceUser
        .create(data, this.user.id || this.userId || JSON.parse(localStorage.user).id)
        .then(async () => {
          this.$bvToast.toast(this.$t('views.seller.my_account.text_1197'), {
            title: this.$t('views.seller.my_account.text_1198'),
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true
          })
          this.updateBankAccountType()
          if(!this.kyc_personal_checklist) await this.fetchUserSettings();
          await this.$store.dispatch('userRequest')
          if(this.isRecebedor) this.$emit('update-account-type', this.recebedorType)
          this.closeModal();
          this.$bvModal.hide(this.id ? this.id : "modal-pf-account")
          setTimeout(() => {
            this.restModal()
          }, 1000);
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    restModal() {
      this.step = 0
      this.type = 'PF'
      this.user = {
        name: null,
        cpf: null,
        birth_date: null,
        mother_name: null,
        cellphone: '',
        country_phone: '',
        is_politically_exposed_person: null,
        accepted_terms: false
      }
      this.address = {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null
      }
      this.company = {
        name: null,
        fantasy_name: null,
        cnpj: null
      }
      this.address_company = {
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null
      }
      this.whatsapp = ''
      this.cpnjIsValid = true
      this.use_same_pf_address = true
    },
    onUpdatePessoal(payload) {
      if(payload.phoneNumber) {
        this.user.cellphone = `+${payload.countryCallingCode+payload.phoneNumber.replace(' ', '').replace('-','').replace('(','').replace(')','')}`
        this.user.country_phone = payload.countryCode
      }
    },
    async openModal() {
      this.user = {
        name: this.userLogged.name,
        cpf_cnpj: this.userLogged.cpf_cnpj,
        birth_date: this.userLogged.birth_date,
        mother_name: this.userLogged.mother_name,
        enriched_data: this.userLogged.enriched_data,
        is_politically_exposed_person: this.userLogged.is_politically_exposed_person === 'true' ? true : false,
        cellphone: this.userLogged.cellphone,
        country_phone: this.userLogged.country_phone,
        accepted_terms: false
      }
      this.isChecked = this.userLogged.is_politically_exposed_person === 'true' ? true : false
      this.updateCpfIsValid(this.user.cpf_cnpj)
      this.whatsapp = this.userLogged.cellphone
      if(this.userLogged.address) {
        this.address = {
          zip_code: this.userLogged.address.zip_code,
          number: this.userLogged.address.number,
          street: this.userLogged.address.street,
          neighborhood: this.userLogged.address.neighborhood,
          city: this.userLogged.address.city,
          state: this.userLogged.address.state,
          complement: this.userLogged.address.complement
        }
      }
      if(this.userLogged.company) {
        this.blocked_company_fields = {
          name: this.userLogged.company.name ? true : false,
          fantasy_name: this.userLogged.company.fantasy_name ? true : false,
          cnpj: this.userLogged.company.cnpj ? true : false,
          documents: this.userLogged.company.documents ? true : false
        }
        this.company = {
          name: this.userLogged.company.name,
          fantasy_name: this.userLogged.company.fantasy_name,
          cnpj: this.userLogged.company.cnpj,
          documents: this.userLogged.company.documents
        }
        this.updateCnpjIsValid(this.company.cnpj)
        if(this.userLogged.company.zip_code) {
          this.address_company = {
            zip_code: this.userLogged.company.zip_code,
            number: this.userLogged.company.number,
            street: this.userLogged.company.street,
            neighborhood: this.userLogged.company.neighborhood,
            city: this.userLogged.company.city,
            state: this.userLogged.company.state,
            complement: this.userLogged.company.complement
          }
          this.blocked_company_fields.zip_code = this.userLogged.company.zip_code ? true : false;
          this.blocked_company_fields.number = this.userLogged.company.number ? true : false;
          this.blocked_company_fields.street = this.userLogged.company.street ? true : false;
          this.blocked_company_fields.neighborhood = this.userLogged.company.neighborhood ? true : false;
          this.blocked_company_fields.city = this.userLogged.company.city ? true : false;
          this.blocked_company_fields.state = this.userLogged.company.state ? true : false;
          this.blocked_company_fields.complement = this.userLogged.company.complement ? true : false;
        }
      }
      
      this.use_same_pf_address = true

      if(this.isRecebedor || this.recebedorType) {
        this.step = 2
        this.type = this.recebedorType
      } else {
        this.step = 0
      }
      await this.getStatusValidation()
    },
    startIdentityVerification() {
      this.step = 1;
    },
    selectType(type) {
      this.type = type
      this.step = 2
    },
    nameValidationSteps(name){
      const validations_name = [
        { step: this.$t('views.seller.validation_account_type.text_1'), validated: /[a-z]/.test(name) && /[A-Z]/.test(name) },
        { step: this.$t('views.seller.validation_account_type.text_2'), validated: /[A-Za-z]\s[A-Za-z]/.test(name) },
        { step: this.$t('views.seller.validation_account_type.text_3'), validated: /^\S+(\s\S+)*$/.test(name) },
        { step: this.$t('views.seller.validation_account_type.text_4'), validated: /^\D*$/.test(name) },
        // { step: this.$t('views.seller.validation_account_type.text_5'), validated: /^[A-Za-z\s]*$/.test(name) },
      ];
      return validations_name ;
    },
    nameMotherValidationSteps(mother_name){
      const validations_mother_name = [
        { step: this.$t('views.seller.validation_account_type.text_1'), validated: /[a-z]/.test(mother_name) && /[A-Z]/.test(mother_name) },
        { step: this.$t('views.seller.validation_account_type.text_2'), validated: /[A-Za-z]\s[A-Za-z]/.test(mother_name) },
        { step: this.$t('views.seller.validation_account_type.text_3'), validated: /^\S+(\s\S+)*$/.test(mother_name) },
        { step: this.$t('views.seller.validation_account_type.text_4'), validated: /^\D*$/.test(mother_name) },
        // { step: this.$t('views.seller.validation_account_type.text_5'), validated: /^[A-Za-z\s]*$/.test(mother_name) },
      ];
      return validations_mother_name ;
    },
    async nextStep() {
      if(this.showNameValid && this.step === 2){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_2'), {
            title: this.$t('views.seller.settings.text_1418'),
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
          return
      }
      if(this.showMotherNameValid && this.step === 2){
        this.$bvToast.toast(this.$t('views.seller.my_account.text_1569_3'), {
            title: this.$t('views.seller.settings.text_1418'),
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
          return
      }
      if(this.showZipCodeValid && this.step === 3){
        this.$bvToast.toast(this.$t('seller.address.listagem_enderecos.fill_postal_code_2'), {
            title: this.$t('views.seller.settings.text_1419'),
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
          return
      }
      let isValid = false
      switch (this.step) {
        case 1:
          if(type) isValid = true
          break;
        case 2:
          this.updateCpfIsValid(this.user.cpf_cnpj);
          await this.$validator.validateAll().then(async (result) => {
            if (result && this.cpfIsValid) isValid = true
          })
          break;
          case 3:
            await this.$validator.validateAll().then(async (result) => {
            if (result) isValid = true
          })
          break;
        case 4:
          await this.$validator.validateAll().then(async (result) => {
            if (result && this.cpnjIsValid) isValid = true
          })
          break;
      }
      if(isValid) {
        if(((this.type === 'PJ' || this.type === 'PENDING_PJ') && this.step === 4) || (this.type === 'PF' && this.step === 3)) {
          this.updateUser();
          this.$emit("update-product");
        } else {
          this.step++
        }
      }
    },
    updateCnpjIsValid(cnpj) {
      this.cpnjIsValid = validateCNPJ(cnpj)
    },
    updateCpfIsValid(cpf) {
      this.cpfIsValid = validateCPF(cpf)
    },
    preencherCep(tipo) {
      let cep = '';
      if (tipo == 'empresa') {
        cep = this.address_company.zip_code.replace(/\D/g, '');
      } else if (tipo == 'usuario') {
        cep = this.address.zip_code.replace(/\D/g, '');
      }
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => response.json())
          .then((response) => {
            if (tipo == 'empresa') {
              this.address_company.street = response.logradouro;
              this.address_company.neighborhood = response.bairro;
              this.address_company.city = response.localidade;
              this.address_company.state = response.uf;
              this.address_company.complement = response.complemento
              if (document.querySelector('#num_company')) {
                document.querySelector('#num_company').focus();
              }
            } else {
              this.address.street = response.logradouro;
              this.address.neighborhood = response.bairro;
              this.address.city = response.localidade;
              this.address.state = response.uf;
              this.address.complement = response.complemento
              if (document.querySelector('#number')) {
                document.querySelector('#number').focus();
              }
            }
          });
      }
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    async getStatusValidation(id) {
      this.loadingGetValidations = true
      await serviceValidation
        .read('status')
        .then(res => {
          this.status_validation = res;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: 'INITIATED',
              observation: ''
            }
          }
        })
        .catch(err => console.error(err))
        .finally(() => this.loadingGetValidations = false)
    },
    checkStatus(docs, status) {
      if (!docs && docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
  }
}
</script>

<style lang="scss">
.btn-start-verification {
  border-radius: 10px;
  background: #141414;
  width: 297px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.icon-verification {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 158, 68, 0.1);
  margin-bottom: 30px;
}
.identity-verification-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title-verification {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(20, 20, 20, 1);
  margin-bottom: 20px;
}
.subtitle-verification {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: rgba(129, 133, 142, 1);
  margin-bottom: 30px;
  text-align: center;
}
ul.custom-ul {
  list-style-type: none;
  padding-left: 1em; 
}

li.custom-li::before {
  content: "-";
  display: inline-block;
  width:  0.8em;
  margin-left: -1em;
}
.information{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: rgb(129, 133, 142);
}
.name_valid{
  color: green;
}
.name_invalid{
  color: #dc3545;
}
.modal-pf-account-border {
  border: solid 1px #CCC;
  border-radius: 10px;
}
.modal-pf-account-border.active {
  border: solid 1px #000;
  border-radius: 10px;
}

.modal-pf-account-description {
  opacity: 0.5;
}
.modal-pf-account-description.active {
  opacity: 1;
}

.modal-content .modal-header {
  border-bottom: 1px solid #fff !important;
}
.modal-content .modal-footer {
  border-top: 1px solid #fff !important;
}
.modal-pf-account-title {
  color: #141414;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.modal-pf-account-subtitle {
  color: #141414;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.modal-pf-account-text {
  color: #141414;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
#modal-pf-account___BV_modal_content_ {
  margin-top: 78px;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #333333 !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}

.have-account {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(129, 133, 142, 1);
}
.modal-pf-account-info-checkbox {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.modal-pf-account-descripcao-checkbox {
  display:block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 12px;
  line-height: 1.5;
}

.custom-error {
  line-height: 14px;
  background-color: rgba(255, 158, 68, 0.10);
  border: solid 0;
  border-radius: 5px;
}

.custom-error-text {
  color: #141414;
  font-size: 13px;
  font-weight: 400;
}
.custom-error-subtitle {
  color: #FF9E44;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
}
.advise-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 20px;
}
.advise-text {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(129, 133, 142, 1);
}
.advise-icon {
  padding: 15px;
  padding-left: 0px;
}
.have-account {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 525;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(0, 0, 0);
}
.warning{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>